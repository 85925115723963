/* src/components/TravelMap.css */

.custom-marker {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
  }
  
  .marker-pin {
    width: 15px;
    height: 15px;
    background-color: #ff0000; /* Red color for the marker */
    border-radius: 50%;
    position: relative;
  }

  .marker-pin-future {
    width: 15px;
    height: 15px;
    background-color: #00ccff;
    border-radius: 50%;
    position: relative;
  }

  /* Style the world seen info */
  .world-seen-info {
    position: absolute;
    top: 20px;
    right: 20px;
    background: rgba(0, 0, 0, 0.7); /* Dark semi-transparent background */
    color: white;
    padding: 15px;
    border-radius: 10px;
    font-size: 16px;
    z-index: 1000; /* Ensure it's on top of the map */
  }
  
  .marker-pin::after {
    content: '';
    position: absolute;
    bottom: -5px;
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 10px solid #ff0000; /* Red color for the pin's tail */
  }

  .marker-pin-future::after {
    content: '';
    position: absolute;
    bottom: -5px;
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 10px solid #00ccff; 
  }
  
  /* Make sure the container fills the viewport */
  .map-container {
    height: 100vh;
    width: 100%;
    position: relative;
  }


  /* Style for popups */
  .popup-content {
    text-align: center;
    font-size: 14px;
  }

  /* Style the map container */
  .leaflet-container {
    filter: grayscale(30%); /* Apply a filter to the map for a different visual effect */
  }

  .legend {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
  }

  .legend div {
    margin: 0 10px;
  }

  .legend-icon {
    display: inline-block;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    margin-right: 5px;
  }

  .legend-icon.visited {
    background-color: #ff0000; /* Red for visited locations */
  }

  .legend-icon.future {
    background-color: #00ccff; /* Blue for future locations */
  }

  /* Responsive Styles */
@media (max-width: 768px) {
  .world-seen-info {
      top: 70%;
      left: 5%;
      position: absolute;
  }
}

@media (max-width: 480px) {
  
}